import {withOneTrust} from '@segment/analytics-consent-wrapper-onetrust';
import {AnalyticsBrowser} from '@segment/analytics-next';
import {load} from '@fingerprintjs/botd';

import {ObjectOfAny} from '@fo/shared-types';
import {isBrowser} from '@fo/shared/utils/browserSupport';
import getFlags from '@fo/shared-getters/getFlags';

import segmentEvents from './segmentEvents';

export {segmentEvents};

const retryCooldown = 2000;

type IdentifyUser = {
  userId: string;
  email: string;
  name: string;
};

export const initSegment = async (): Promise<undefined> => {
  const {SEGMENT_ACTIVE} = getFlags();
  if (!isBrowser || !SEGMENT_ACTIVE) {
    return;
  }

  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  // Sets temp gtag before initialisation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const gtag = (...args: any) => {
    window.dataLayer?.push(args);
  };

  // Set default consent to 'denied' as a placeholder
  gtag('consent', 'default', {
    ad_storage: 'denied',
    analytics_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    functionality_storage: 'denied',
  });

  // Inits Segment script
  window.fundingOptions.segment = new AnalyticsBrowser();

  // Initialize an agent at application startup, once per page/app -> to detect bots.
  const botdPromise = load();
  // Get detection results when you need them.
  botdPromise
    .then((botd) => botd.detect())
    .then(async (result) => {
      // marks as bot for future reference
      window.fundingOptions.isBot = result.bot;
      // ...and aborts Segment's initialisation
      if (result.bot) {
        return;
      }

      // Inits CookiePro
      withOneTrust(window.fundingOptions.segment as AnalyticsBrowser, {consentModel: () => 'opt-out'}).load({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        writeKey: process.env.SEGMENT_WRITE_KEY!,
      });
    });
};

export const identifyUser = ({userId, email, name}: IdentifyUser): void => {
  if (window?.fundingOptions?.segment || window?.fundingOptions?.isBot) {
    return;
  }
  try {
    window?.fundingOptions?.segment?.identify(userId, {
      name,
      email,
    });
  } catch {
    // eslint-disable-next-line no-console
    console.error('Error identifying user in Segment');
  }
};

export const track = (event: string, values: ObjectOfAny = {}): void => {
  if (!window || window?.fundingOptions?.isBot) {
    return;
  }

  const stringfiedValues = JSON.parse(JSON.stringify(values));

  if (!window.fundingOptions?.segment) {
    window.setTimeout(() => {
      track(event, values);
    }, retryCooldown);
    return;
  }
  window.fundingOptions.segment.track(event, {
    ...stringfiedValues,
    isSegmentEvent: true,
    featureFlags: window?.fundingOptions?.flags,
  });
};

export const logPageVisit = (): void => {
  if (!window || window?.fundingOptions?.isBot) {
    return;
  }
  if (!window.fundingOptions?.segment || !window?.fundingOptions?.flags) {
    window.setTimeout(() => {
      logPageVisit();
    }, retryCooldown);
    return;
  }
  window.fundingOptions.segment.page({
    keywords: JSON.stringify(window.fundingOptions.flags),
  });
};
